import superagentPromise from "superagent-promise";
import _superagent from "superagent";
// import { configureStore } from "../store";

const superagent = superagentPromise(_superagent, Promise);

const handleErrors = async (err) => {
    if (err && err.status === 401) {
        return null;
    }
};

const responseBody = (res) => res.body;
const apiUrl = window.__ENV && window.__ENV.apiUrl;
const requests = {
    post: (url, body) =>
        superagent
            .post(url, body)
            .set("Content-Type", "application/json")
            .on("error", handleErrors)
            .then(responseBody),
    postData: (url, body) =>
        superagent
            .post(url, body)
            .set("Content-Type", "application/x-www-form-urlencoded")
            .then(responseBody),
    getWithToken: (url, token) =>
        superagent
            .get(url)
            .set("Content-Type", "application/json")
            .set("Authorization", `Bearer ${token}`)
            .on("error", handleErrors)
            .then(responseBody),
    postWithToken: (url, body, token) =>
        superagent
            .post(url, body)
            .set("Content-Type", "application/json")
            .on("error", handleErrors)
            .set("Authorization", `Bearer ${token}`)
            .then(responseBody),
    deleteWithToken: (url, token) =>
        superagent
            .del(url, token)
            .set("Content-Type", "application/json")
            .on("error", handleErrors)
            .set("Authorization", `Bearer ${token}`)
            .then(responseBody),
    putWithToken: (url, body, token) =>
        superagent
            .put(url, body, token)
            .set("Content-Type", "application/json")
            .on("error", handleErrors)
            .set("Authorization", `Bearer ${token}`)
            .then(responseBody),
};

const Organization = {
    register: async (data) => {
        return requests.postWithToken(`${apiUrl}/api/WebRegister/Create`, data);
    }
}

export default { Organization }