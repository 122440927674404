import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

//import images
// import features1 from "../assets/images/features/img-1.png";
// import features2 from "../assets/images/features/img-2.png";
import leaveImage from "../assets/images/features/1.jpg"
import employeeImage from "../assets/images/features/4.jpg"
import adminImage from "../assets/images/features/6.jpg"
import timesheetImage from "../assets/images/features/8.jpg"
import projectImage from "../assets/images/features/9.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faQuoteLeft, faQuoteRight, faFileAlt, faUsers, faLandmark, faCalendarAlt, faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import "./Features.css"

const cardData = [
  {
    cardNo: 1,
    image: leaveImage,
    imgHgt: "150px",
    title: "Leave Management",
    content: "We offer a leave tracker that includes custom week offs, various leave types, and the ability to customize the number of leave days. Employees can apply for leave, and managers can approve or reject leave requests."
  },
  {
    cardNo: 2,
    image: employeeImage,
    imgHgt: "150px",
    title: "Employee Management",
    content: "Effortlessly manage and update your employee information, keeping your records current with ease. Seamlessly maintain an organized, up- to - date database for efficient employee management."
  },
  {
    cardNo: 3,
    image: adminImage,
    imgHgt: "150px",
    title: "Administrative Process",
    content: "Empower your team with group-based role management and customizable feature assignments to unleash their full potential within your organization."
  },
  {
    cardNo: 4,
    image: timesheetImage,
    title: "Timesheet Management",
    imgHgt: "150px",
    content: "Track employee performance, monitor progress, and gauge efficiency with ease. Generate insightful reports to evaluate and enhance employee performance effectively."
  },
  {
    cardNo: 5,
    image: projectImage,
    title: "Project Management",
    imgHgt: "150px",
    content: "Transform your project workflow with ease! Our platform streamlines task assignment and slashes completion time, packed with features to help you plan and execute projects perfectly, every time."
  },
]
const Features = () => {
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(3);
  const [showCards, setShowCard] = useState([])

  useEffect(() => {
    setShowCard(cardData.filter((data) => data.cardNo >= start && data.cardNo <= end))
    // 
  }, [start, end])

  const handlBackward = () => {
    if (start > 0) {
      setStart(start - 1);
      setEnd(end - 1);
    }
  }
  const handlForward = () => {
    if (end < 5) {
      setEnd(end + 1);
      setStart(start + 1);
    }
  }

  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  {/* <span className="fw-normal">The Features that </span> we
                  present */}
                  FEATURES WE OFFERED
                </h3>
                {/* <p className="heading-desc text-muted mt-3">
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value for our clients.
                </p> */}
                <p className="heading-desc text-muted mt-3">
                  <FontAwesomeIcon icon={faQuoteLeft} color="#12a894" />
                  &nbsp;&nbsp;&nbsp;We offer a wide range of features to effectively manage your organization.
                  Our solutions enable you to increase production and revenue, while reducing workload and operational expenses.
                  Our self-service portals empower your employees to apply for leaves, submit work reports, and more. &nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faQuoteRight} color="#12a894" />
                </p>
              </div>
            </Col>
          </Row>
          {/* <Row className="mt-5 d-flex align-items-center justify-content-center">
            <Col md="1">
              {start !== 1 &&
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  style={{
                    height: "70px",
                    cursor: "pointer"
                  }}
                  color="#12a894"
                  onClick={handlBackward}
                />
              }
            </Col>
            {showCards && showCards.map((item) => (
              <Col md='3' >
                <Card className="card-style" style={{ minHeight: "450px" }}>
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      //  display: "block",
                      height: item.imgHgt,
                      width: "auto",
                    }}
                  />
                  <span className="heading-title text-uppercase fw-bold text-center py-3 text-primary">
                    {item.title}
                  </span>
                  <div className="">
                    <p className="px-2" className="text-justify">
                      {item.content}
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
            <Col md="1">
              {
                end !== 5 &&
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  style={{
                    height: "70px",
                    cursor: "pointer"
                  }}
                  color="#12a894"
                  onClick={handlForward}
                />
              }
            </Col>
          </Row> */}
          {/* <Row className="mt-5 pt-4 align-items-center">
            <Col lg={5}>
              <div className="mt-4">
                <div className="mt-4">
                  <h3>
                    <span className="fw-normal">We love make things </span>
                    amazing and simple
                  </h3>
                </div>
                <div className="mt-4 pt-3">
                  <div className="d-flex">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">01</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Marketing Performance</h5>
                      <p className="text-muted mt-2">
                        Separated they live in Bookmarksgrove right at the coast
                        the Semantics, a large language ocean. A small river
                        name Duden flows by regelialia.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">02</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Marketing business</h5>
                      <p className="text-muted mt-2 mb-0">
                        Separated they live in Bookmarksgrove right at the coast
                        of the Semantics, a large language ocean publishing web
                        page editors now.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="offset-lg-1">
              <div className="mt-4 p-5">
                <img src={features1} className="img-fluid" alt="" />
              </div>
            </Col>
          </Row> */}

          {/* <Row className="mt-5 pt-4 align-items-center">
            <Col lg={6}>
              <div className="mt-4 p-5">
                <img src={features2} className="img-fluid" alt="" />
              </div>
            </Col>

            <Col lg={5} className="offset-lg-1">
              <div className="mt-4">
                <h3>
                  <span className="fw-normal">Creative solutions to </span>{" "}
                  expand your business!
                </h3>
              </div>

              <div className="mt-4 pt-3">
                <div className="d-flex">
                  <div className="features-icon">
                    <i className="pe-7s-display2 text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">
                      We put a lot of effort in design.
                    </h5>
                    <p className="text-muted mt-2">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content. Moltin gives
                      platform web sites still in their infancy.
                    </p>
                  </div>
                </div>

                <div className="d-flex mt-3">
                  <div className="features-icon">
                    <i className="pe-7s-date text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">Submit Your Orgnization.</h5>
                    <p className="text-muted mt-2">
                      Credibly brand standards compliant on users without
                      extensible services. Anibh euismod tincidunt laoreet Ipsum
                      combined with a passage.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="mt-4">
            <Col lg="12">
              <Card className="p-3 card-shadow">
                <Row>
                  <Col lg="5" className="d-flex justify-content-center align-items-center">
                    <div>
                      <img src={leaveImage} style={{ height: "200px", width: "auto" }} />
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="text-center text-uppercase fw-bold my-2 text-light-blue">
                      <FontAwesomeIcon icon={faFileAlt} /> &nbsp; Leave Management
                    </div>
                    <ul className="list-unstyled me-4 text-justify">
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-light-blue" />
                        </div>
                        We offer a leave tracker with custom week offs, multiple leave types, and adjustable leave day limits.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-light-blue" />
                        </div>
                        Employees can apply for leave, and managers can approve or reject requests.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-light-blue" />
                        </div>
                        View your leave balance and apply easily.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg="12" className="mt-4">
              <Card className="p-3 card-shadow">
                <Row>
                  <Col lg="7">
                    <div className="text-center text-uppercase fw-bold my-2 text-blue">
                      <FontAwesomeIcon icon={faUsers} /> &nbsp; Employee Management
                    </div>
                    <ul className="list-unstyled ms-4 text-justify">
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-blue" />
                        </div>
                        Easily manage and update employee information to keep records current.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-blue" />
                        </div>
                        Effortlessly maintain an organized, up-to-date database for efficient employee management.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-blue" />
                        </div>
                        Easily maintain employee-manager relationships.
                      </li>
                    </ul>
                  </Col>
                  <Col lg="5" className="d-flex justify-content-center align-items-center">
                    <div>
                      <img src={employeeImage} style={{ height: "200px", width: "auto" }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <Card className="p-3 card-shadow">
                <Row>
                  <Col lg="5" className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <img src={adminImage} style={{ height: "200px", width: "auto" }} />
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="text-center text-uppercase text-green fw-bold py-2">
                      <FontAwesomeIcon icon={faLandmark} /> &nbsp; Administrative Process
                    </div>
                    <ul className="list-unstyled me-4 text-justify">
                      <li className="d-flex gap-3 align-items-center">
                        <div>
                          <div className="custom-bullet-green" />
                        </div>
                        Empower your team with group roles and customizable features to unlock their potential.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-green" />
                        </div>
                        Easily manage access for each employee.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-green" />
                        </div>
                        Here, you can customize the app's logo and name to reflect your brand.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg="12" className="mt-4">
              <Card className="p-3 card-shadow">
                <Row>
                  <Col lg="7">
                    <div className="text-center text-uppercase text-orange fw-bold py-2">
                      <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; Timesheet Management
                    </div>
                    <ul className="list-unstyled ms-4 text-justify">
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-orange" />
                        </div>
                        Track employee performance, monitor progress, and gauge efficiency with ease.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-orange" />
                        </div>
                        Generate insightful reports to evaluate and enhance employee performance effectively.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-orange" />
                        </div>
                        Monitor project progress with detailed daily user activity through timesheet management.
                      </li>
                    </ul>
                  </Col>
                  <Col lg="5" className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <img src={timesheetImage} style={{ height: "200px", width: "auto" }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <Card className="p-3 card-shadow">
                <Row>
                  <Col lg="5" className="d-flex justify-content-center align-items-center">
                    <div>
                      <img src={projectImage} style={{ height: "180px", width: "auto" }} />
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="text-center text-uppercase text-violet fw-bold py-2">
                      <FontAwesomeIcon icon={faLaptopCode} />  &nbsp; Project Management
                    </div>
                    <ul className="list-unstyled me-4 text-justify">
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-violet" />
                        </div>
                        We offer visual task representations to simplify project tracking.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-violet" />
                        </div>
                        Optimizes workflows and significantly reduces inefficiencies, ensuring smoother operations and better resource utilization.
                      </li>
                      <li className="d-flex gap-3 align-items-center mt-3">
                        <div>
                          <div className="custom-bullet-violet" />
                        </div>
                        Clearly assigns tasks and deadlines, ensuring accountability and enabling effective tracking of responsibilities.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment >
  );
};

export default Features;
