import React from "react";
import { Col, Container, Row } from "reactstrap";

const Contact = () => {
  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  <span className="fw-normal">Let's talk about</span>{" "}
                  everything!
                </h3>
                <p className="heading-desc text-muted mt-3">
                  We thrive when coming up with innovative ideas but also
                  understand that a smart concept should be supported with
                  faucibus sapien odio measurable results.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4 justify-content-center">
            {/* <Col lg={3}>
              <div className="mt-4">
                <h5 className="f-17 mb-1 mt-4 pt-2">Email Address</h5>
                <p className="text-muted">JohnPBeau@jourrapide.com</p>
                <div className="mt-4">
                  <h5 className="f-17 mb-1">Contact Number</h5>
                  <p className="text-muted">+112 708-231-9668</p>
                </div>
                <div className="mt-4">
                  <h5 className="f-17 mb-1">Office Address</h5>
                  <p className="text-muted">
                    3429 Gnatty Creek Road Farmingdale, NY 11735
                  </p>
                </div>
              </div>
            </Col> */}

            <Col lg={8}>
              <div className="custom-form mt-4">
                <form method="post" name="myForm">
                  <p id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group mt-2">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Your name"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group mt-2">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your email"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Your Subject.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-2">
                        <textarea
                          name="comments"
                          id="comments"
                          rows={4}
                          className="form-control"
                          placeholder="Your message..."
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-rounded btn-primary"
                        value="Send Message"
                      />
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
