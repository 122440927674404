import React, { useEffect } from "react";
import Routes from "./Routes/index";

//import Custom Style scss
import "./assets/scss/themes.scss";

//import Icons
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/materialdesignicons.min.css";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Send page view to Google Analytics whenever the route changes
    if (window.gtag) {
      window.gtag('config', 'G-ERKPLXNDVH', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
