import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { clearMessage, createLandingPageSuccess, notifyMessage, register } from "../../store/Register/actions";
import useGoogleReCaptcha from "../../components/Recaptcha";
import { Link } from "react-router-dom";

const Section = (props) => {
  const { message } = props;
  const redirectURL = window.__ENV && window.__ENV.redirectURL;
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [registerData, setRegister] = useState({});
  const [errors, setErrors] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha('6LfzegwqAAAAAMBUQuSbShPesTzL7jQAPm_4FxkT');

  useEffect(() => {
    props.clearMessage();
    props.createLandingPageSuccess();
    return () => {
      props.clearMessage();
    }
  }, [])

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRegister({
      ...registerData,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: null
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, userEmail, orgName } = registerData || {};
    let currentErrors = {};
    const recaptchaToken = await executeRecaptcha('homepage');

    if (!(firstName && firstName.trim())) {
      currentErrors.firstName = "First name is required";
    }
    if (!(lastName && lastName.trim())) {
      currentErrors.lastName = "Last name is required";
    }
    if (!(userEmail && userEmail.trim())) {
      currentErrors.userEmail = "Email is required";
    }
    if (!(orgName && orgName.trim())) {
      currentErrors.orgName = "Organization name is required";
    }

    setErrors({
      ...errors,
      ...currentErrors
    })

    if (Object.keys(currentErrors).length === 0) {
      console.log("recaptchaToken:", recaptchaToken);

      props.register({ ...registerData, token: recaptchaToken });
      // setFormSubmitted(true);
      // setRegister({});
    }
    else {
      const msg = {
        type: "error",
        content: "Please fill all required fields."
      }
      props.notifyMessage(msg)
    }
  }

  useEffect(() => {
    if (message && message.type === "result") {
      setRegister({});
      console.log("clear1");

      setPage(1);
      setFormSubmitted(false);
    }
  }, [formSubmitted, message]);
  console.log("registerData:", registerData);

  return (
    <React.Fragment>
      <section className="section bg-home-6 align-items-center" id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h1 className="home-title fw-bold text-uppercase">
                  Enhance your business here
                  {/* <span className="fw-bold"> For Freelancers</span> */}
                </h1>

                <p className="home-desc text-muted mt-4">
                  We are providing  software to manage your business .
                  Here you can track , evaluate and manipulate  your business data.
                </p>
                {/* <div className="mt-5">
                  <Link
                    to="#"
                    onClick={toggle}
                    className="btn btn-primary btn-round"
                  >
                    Get Started Now
                    <span className="play-shadow ms-2">
                      <span className="home-btn">
                        <i className="mdi mdi-play text-center"></i>
                      </span>
                    </span>
                  </Link>
                </div> */}
              </div>
            </Col>

            <Col lg={5} className="offset-lg-1">
              <div className="home-registration-form bg-white p-5 mt-4">
                <h5 className="form-title mb-4 text-center">
                  Get Started with Free Evaluation
                </h5>
                {message &&
                  <Alert className="text-break" color={message && message.type === "result" ? "success" : "danger"}>
                    {message && message.content}
                  </Alert>
                }
                <Form className="registration-form">
                  <TabContent activeTab={page}>
                    <TabPane tabId={1}>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">First Name <span className="text-danger">*</span></Label>
                        <Input
                          type="text"
                          id="exampleInputName1"
                          className="form-control mb-0 registration-input-box"
                          name="firstName"
                          onChange={handleChange}
                          value={registerData?.firstName || ''}
                          invalid={errors && errors.firstName}
                        />
                        <FormFeedback>{errors && errors.firstName}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">Last Name <span className="text-danger">*</span></Label>
                        <Input
                          type="text"
                          id="exampleInputName2"
                          className="form-control mb-0 registration-input-box"
                          name="lastName"
                          onChange={handleChange}
                          invalid={errors && errors.lastName}
                          value={registerData?.lastName || ''}
                        />
                        <FormFeedback>{errors && errors.lastName}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">Contact Number</Label>
                        <Input
                          id="exampleInputEmail1"
                          className="form-control mb-0 registration-input-box"
                          name="orgContactNumber"
                          onChange={handleChange}
                          value={registerData?.orgContactNumber || ''}
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="mt-3"
                          color="success"
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(page + 1);
                          }
                          }>
                          Next
                        </Button>
                      </div>
                    </TabPane>
                    {/* </TabContent> */}
                    {/* <TabContent activeTab="2"> */}
                    <TabPane tabId={2}>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">Email <span className="text-danger">*</span></Label>
                        <Input
                          type="email"
                          id="exampleInputEmail1"
                          className="form-control mb-0 registration-input-box"
                          name="userEmail"
                          onChange={handleChange}
                          invalid={errors && errors.userEmail}
                          value={registerData?.userEmail || ''}
                        />
                        <FormFeedback>{errors && errors.userEmail}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">Organization Name <span className="text-danger">*</span></Label>
                        <Input
                          type="text"
                          id="exampleInputName1"
                          className="form-control mb-0 registration-input-box"
                          name="orgName"
                          onChange={handleChange}
                          invalid={errors && errors.orgName}
                          value={registerData?.orgName || ''}
                        />
                        <FormFeedback>{errors && errors.orgName}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label className="text-muted">Organization Address</Label>
                        <Input
                          type="textarea"
                          id="exampleInputName2"
                          className="form-control mb-0 registration-input-box"
                          name="orgAddress"
                          onChange={handleChange}
                          value={registerData?.orgAddress || ''}
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-between">
                        <Button
                          className="mt-3 me-2"
                          color="success"
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(page - 1);
                          }
                          }>
                          Back
                        </Button>
                        <Button
                          className="mt-3"
                          color="success"
                          onClick={handleSubmit}
                          disabled={props.isLandingPagePending}>
                          {props.isLandingPagePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                          </>
                            : <>Submit</>}
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </Form>
                <div className="d-flex gap-1 justify-content-center mt-5">
                  <div className="text-secondary">
                    Already have an account?
                  </div>
                  <Link to={redirectURL} className="text-primary">
                    Log In
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
          <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section >
    </React.Fragment >
  );
};

const mapStateToProps = (state) => ({
  message: state.register.message,
  isLandingPagePending: state.register.isLandingPagePending,
})

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(register(data)),
  notifyMessage: (data) => dispatch(notifyMessage(data)),
  clearMessage: () => dispatch(clearMessage()),
  createLandingPageSuccess: () => dispatch(createLandingPageSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Section);
