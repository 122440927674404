import React, { useState, useEffect } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";

//import images
import logoLight from "../assets/images/VizoLogo.png";
import logoDark from "../assets/images/VizoLogo.png";
import { Link } from "react-router-dom";

const NavBar = ({ isDark }) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  const isDarkClass = isDark ? "navbar-white" : "";

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top " +
          isDarkClass +
          " navbar-custom sticky sticky-light " +
          navClass
        }
        id="navbar"
      >
        <Container>
          <Link className="navbar-brand logo text-uppercase" to="/">
            {isDark ? (
              <>
                <img src={logoDark} className="logo-light" alt="" height="60" />
                <img src={logoDark} className="logo-dark" alt="" height="60" />
              </>
            ) : (
              <>
                <img src={logoDark} className="logo-dark" alt="" height="60" />
                <img
                  src={logoLight}
                  className="logo-light"
                  alt=""
                  height="24"
                />
              </>
            )}
          </Link>
          <NavbarToggler
            className="navbar-toggler"
            type="button"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            isOpen={isOpenMenu}
            className="navbar-collapse"
            id="navbarNav"
          >
            <Scrollspy
              offset={-18}
              items={[
                "home",
                // "services",
                "features",
                // "client",
                // "pricing",
                "contact",
              ]}
              currentClassName="active"
              className="navbar-nav ms-auto navbar-center"
            // id="mySidenav"
            >
              <li className="nav-item">
                <NavLink href="#home">Home</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink href="#services">Services</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink href="#features">Features</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink href="#client">Clients</NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink href="#pricing">Pricing</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink href="#contact">Contact</NavLink>
              </li>
            </Scrollspy>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
