import { CLEAR_MESSAGE, CREATE_LANDING_PAGE_PENDING, CREATE_LANDING_PAGE_SUCCESS, NOTIFICATION_MESSAGE } from "./constants";

const initialState = {
    message: null,
    isLandingPagePending: false,
};

const register = (localState = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_MESSAGE: {
            const { message } = action.payload;
            return {
                ...localState,
                message: message,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...localState,
                message: null,
            };
        }
        case CREATE_LANDING_PAGE_PENDING: {
            return {
                ...localState,
                isLandingPagePending: true,
            };
        }
        case CREATE_LANDING_PAGE_SUCCESS: {
            return {
                ...localState,
                isLandingPagePending: false,
            };
        }
        default: {
            return localState;
        }
    }
};

export default register;